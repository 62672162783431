import { remove } from "@/api/getApi2.0";

import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const deleteEpisode = (programId, episodeId, axios) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.path,
      ressources: [
        {
          name: programId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.name,
          value: episodeId
        }
      ]
    },
    {},
    axios
  );

export const cleanImages = (programId, episodeId, axios) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path,
      ressources: [
        {
          name: programId,
          value: ENTITY_NAME_IN_URI_V2.PROGRAMS.EPISODES.path
        },
        {
          name: episodeId,
          value: ENTITY_NAME_IN_URI_V2.PROGRAMS.EPISODES.clean
        }
      ]
    },
    {},
    axios
  );
