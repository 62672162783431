<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-advanced"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_3"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.TITLE") }}
          </span>
        </div>
      </div>

      <div
        id="collapse_3"
        class="collapse"
        data-parentId="#accordion-form-advanced"
      >
        <div class="card-body">
          <div class="d-flex flex-row justify-content-between">
            <div
              v-if="hasAccess('homeDisplay', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0">{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_HOME")
              }}</label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="formData.homeDisplay"
                  size="lg"
                  name="check-button"
                  switch
                  :disabled="!hasAccess('homeDisplay', 'write')"
                />
              </div>
            </div>

            <div
              v-if="hasAccess('isForced', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0">{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_FORCE")
              }}</label>

              <div class="d-flex flex-column">
                <b-form-checkbox
                  v-model="formData.isForced"
                  size="lg"
                  name="check-button"
                  switch
                  :disabled="!hasAccess('isForced', 'write')"
                />
              </div>
            </div>
          </div>

          <div
            v-if="hasAccess('tags', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{
                $tc(
                  "ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_CATEGORIES"
                )
              }}
              <HelperTooltip icon="mdi-help-circle" text="Placeholder" />
            </label>

            <div class="d-flex flex-column w-75">
              <TagSelect
                :listOptions="tagIAB"
                buttonText="Choose categories"
                buttonIcon="mdi-flag"
                fieldName="name"
                variant="success"
                :preSelectedElement="tagIABSelected"
                :disabled="!hasAccess('tags', 'write')"
                @change="changetagIAB"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('tags', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_LANGUAGES")
              }}
              <HelperTooltip icon="mdi-help-circle" text="Placeholder" />
            </label>

            <div class="d-flex flex-column w-75">
              <TagSelect
                :listOptions="tagLang"
                buttonText="Choose languages"
                buttonIcon="mdi-flag"
                fieldName="name"
                variant="info"
                :preSelectedElement="tagLangSelected"
                @change="changetagLang"
                :disabled="!hasAccess('tags', 'write')"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('cluster', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_PARTITION")
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-select
                v-model="formData.cluster"
                :options="partitionList"
                class="form-control--grey"
                :disabled="!hasAccess('cluster', 'write')"
              ></b-form-select>
            </div>
          </div>

          <div
            v-if="hasAccess('parentId', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_PARENT")
              }}
              <HelperTooltip icon="mdi-help-circle" text="Placeholder" />
            </label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="formData.parentId"
                class="form-control--grey"
                :disabled="!hasAccess('parentId', 'write')"
              />
            </div>
          </div>

          <div
            v-if="hasAccess('redirectTo', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25">{{
              $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_ADVANCED.LABEL_REDIRECT_TO")
            }}</label>

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="formData.redirectTo"
                class="form-control--grey"
                :disabled="!hasAccess('redirectTo', 'read')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagSelect from "@/components/TagSelectV2";
import HelperTooltip from "@/components/HelperTooltip";
import { filterTagsByType } from "@/common/entities.service";
import { getTags } from "@/api/categories";
import { FORM_ADVANCED_PLAYLIST } from "@/common/config/acl/programs/playlist/abac";
import {
  hasAttributeAccess,
  getAttributesRead
} from "@/common/config/acl/index";
import { FORM_ADVANCED } from "@/common/config/acl/programs/abac";
import { partitionList } from "@/components/settings/formsPogram/programs.config.js";

export default {
  props: {
    form: {
      type: Object,
      required: false
    },
    programId: {
      type: String,
      required: false
    },
    currentUser: {
      props: Object,
      required: true
    }
  },

  components: {
    TagSelect,
    HelperTooltip
  },

  data() {
    return {
      formData: getAttributesRead(
        this.isFormPlaylist ? FORM_ADVANCED_PLAYLIST : FORM_ADVANCED,
        this.currentUser.roles
      ),
      tagIAB: [],
      tagIABSelected: [],
      tagLang: [],
      tagLangSelected: [],
      partitionList
    };
  },

  mounted() {
    this.loadTags();
    if (this.programId) {
      this.formData.homeDisplay = this.form.homeDisplay;
      this.formData.isForced = this.form.isForced;
      this.formData.cluster = this.form.cluster;
      this.formData.parentId = this.form.parentId;
      this.formData.redirectTo = this.form.redirectTo;

      if (this.form.tags?.length > 0) {
        const { tags, languages } = filterTagsByType(this.form.tags || []);
        this.tagIABSelected = tags;
        this.tagLangSelected = languages;
      }
    }
  },

  methods: {
    async loadTags() {
      const response = await getTags(this.axios);
      const { tags, languages } = filterTagsByType(response.data);
      this.tagIAB = tags;
      this.tagLang = languages;
    },

    hasAccess(field, accessType) {
      return hasAttributeAccess(
        this.isFormPlaylist ? FORM_ADVANCED_PLAYLIST : FORM_ADVANCED,
        this.currentUser.roles,
        field,
        accessType
      );
    },

    changetagIAB(items) {
      this.tagIABSelected = items;
    },

    changetagLang(items) {
      this.tagLangSelected = items;
    },

    resetData() {
      this.formData = getAttributesRead(
        this.isFormPlaylist ? FORM_ADVANCED_PLAYLIST : FORM_ADVANCED,
        this.currentUser.roles
      );
      this.tagIABSelected = [];
      this.tagLangSelected = [];
    }
  }
};
</script>
