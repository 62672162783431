<template>
  <div class="d-flex flex-column flex-md-row w-100 px-5">
    <Program @loadPrograms="loadPrograms" ref="program" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Program from "@/components/settings/Program";
import { getAllProgramsPremium } from "@/api/podcasts/getters.js";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    Program
  },

  data() {
    return {
      isLoadingPodcasts: false,
      isCanCheck: false,
      programs: [],
      filters: {
        isplaylist: false,
        limit: 10,
        page: 1
      },
      maxPage: 0,
      isCreating: false
    };
  },

  computed: {
    ...mapGetters({
      isAdmin: "isAdmin"
    }),

    getProgramId() {
      return this.$route.params.id;
    }
  },

  mounted() {
    this.loadPrograms();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Settings",
        pageCategory: "Content"
      }
    ]);

    // if (!this.isAdmin) {
    //   this.$refs.program.typeForm = "playlist";
    // }
  },

  methods: {
    loadPlaylist() {
      this.programs = [];

      if (!this.filters.isplaylist) {
        delete this.filters.isplaylist;
      } else {
        this.filters.page = 1;
      }

      this.loadPrograms();
    },

    async loadPrograms(refresh = false) {
      this.isLoadingPodcasts = true;

      if (refresh) {
        this.programs = [];
      }

      try {
        const response = await getAllProgramsPremium(this.filters, this.axios);
        this.maxPage = Math.round(
          response.total_items / response.items_per_page
        );

        if (refresh) {
          this.programs = response.data;
        } else {
          this.programs = this.programs.concat(response.data);
        }
      } catch (error) {
        console.error("---ERROR-PODCASTS-PREMIUM---");
        console.error(error);
      }

      this.isLoadingPodcasts = false;
    },

    loadMore() {
      this.filters.page += 1;
      this.loadPrograms();
    },

    clickCreate() {
      if (this.isAdmin) {
        this.$refs.program.typeForm = "";
      } else {
        this.$refs.program.typeForm = "playlist";
      }
    }
  },

  watch: {
    "$route.params"() {
      const refProgram = this.$refs.program;
      refProgram.items = [];
      refProgram.episodesLists = [];
      refProgram.isCanCheck = false;
      refProgram.resetData();

      if (this.getProgramId) {
        refProgram.loadConfigurations();
      }
    }
  }
};
</script>

<style scoped>
.link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
.link:hover {
  opacity: 0.8;
}
</style>
