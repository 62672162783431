import { postFile, update } from "../getApi2.0";
import { ENTITY_NAME_IN_URI } from "../config/api2.0";

export const createPlaylist = (body, axios) =>
  postFile(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    {},
    body,
    axios
  );

export const updatePlaylist = (body, programId, axios) =>
  postFile(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path,
      ressources: [
        {
          name: programId
        }
      ]
    },
    {
      _method: "PUT"
    },
    body,
    axios
  );

export const updateStatus = (body, programId, axios) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path,
      ressources: [
        {
          name: programId
        }
      ]
    },
    body,
    axios
  );
