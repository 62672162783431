<template>
  <div>
    <b-card
      border-variant="secondary"
      header-border-variant="secondary"
      class="h-100"
    >
      <v-row class="pb-4">
        <b-input-group class="mt-3">
          <b-form-input
            v-model="search"
            hide-details
            label="Search episode"
            placeholder="Filter by title"
            prepend-inner-icon="search"
            solo
            filled
            color="blue"
            dense
            clearable
            @input="searchHandler"
          ></b-form-input>

          <template #append>
            <b-input-group-text>
              <v-icon>mdi-filter-outline</v-icon>
            </b-input-group-text>
          </template>
        </b-input-group>
      </v-row>

      <b-table
        striped
        hover
        :fields="header"
        :items="items"
        show-empty
        :busy="isLoading"
        aria-controls="listing-episodes"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>{{
              $tc("ADVANCED_SETTINGS.LISTING_EPISODES.LABEL_LOADING")
            }}</strong>
          </div>
        </template>

        <template v-slot:cell(publicationAt)="row">
          <span>{{
            new Date(row.item.publicationAt).toLocaleDateString("fr")
          }}</span>
        </template>

        <template v-slot:cell(duration)="row">
          <span>{{ row.item.duration }}</span>
        </template>

        <template v-slot:cell(action)="row">
          <b-dropdown>
            <template #button-content>
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </template>
            <b-dropdown-item
              v-for="(item, index) in filteredItems"
              :key="`embed_${index}`"
              @click.stop.prevent="action(item, row)"
            >
              <v-icon>{{ item.icon }}</v-icon>
              {{ $tc(item.key) }}

              <input
                type="hidden"
                :id="`${item.selector}-${row.item.id}`"
                class="form-control"
                style="width: 100px"
              />
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <span class="justify-start font-weight-bold"
        >{{ $tc("ADVANCED_SETTINGS.LISTING_EPISODES.TOTAL") }} :
        {{ totalItems }}</span
      >

      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          aria-controls="listing-episodes"
          @change="changePage"
        ></b-pagination>
      </div>
    </b-card>

    <b-modal
      id="modal-delete-episode"
      :title="$tc('ADVANCED_SETTINGS.CONTENT.DELETE_EPISODE.TITLE')"
      hide-header-close
    >
      <p class="my-4">
        {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE_EPISODE.LABEL") }}
      </p>

      <template #modal-footer="{ close }">
        <button
          class="btn border rounded mr-3"
          :disabled="isLoadingDelete"
          @click="close"
        >
          {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE_EPISODE.CANCEL") }}
        </button>

        <button
          class="btn btn-danger mr-3"
          :disabled="isLoadingDelete"
          @click="deleteEpisode(close)"
        >
          <template v-if="!isLoadingDelete">
            {{ $tc("ADVANCED_SETTINGS.CONTENT.DELETE_EPISODE.SUBMIT") }}
          </template>

          <template v-if="isLoadingDelete">
            <b-spinner label="spinning"></b-spinner>
          </template>
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { renderSeconds, convertToSeconds } from "@/common/functions";
import { dropdownProgramAndPlaylist } from "@/components/episodes/embed/dropdown.menu.config";
import Config from "@/common/config.js";
import {
  deleteEpisode,
  cleanImages as cleanImagesEpisode
} from "@/api/episodes/setters";
import { mapGetters } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    header: {
      type: Array,
      required: false
    },
    totalItems: {
      type: Number,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    program: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      showDelete: false,
      search: "",
      timeOutSearch: {},
      currentPage: 1,
      perPage: 10,
      dropdownProgramAndPlaylist,
      isLoadingDelete: false,
      selectedEpisode: {}
    };
  },

  computed: {
    filteredItems() {
      return this.isAdmin()
        ? this.dropdownProgramAndPlaylist
        : this.dropdownProgramAndPlaylist.filter(item => !item.role);
    }
  },

  mounted() {
    this.$root.$on("currentPage", value => {
      this.currentPage = value;
    });
  },

  filters: {
    titleTruncate(title) {
      let cutTitle = title.substring(0, 37);
      if (40 < title.length) {
        cutTitle += "...";
      }

      return cutTitle;
    }
  },

  methods: {
    ...mapGetters(["isAdmin"]),

    renderSeconds(time) {
      return renderSeconds(time);
    },

    convertToSeconds(timeStr) {
      return convertToSeconds(timeStr);
    },

    searchHandler() {
      clearTimeout(this.timeOutSearch);
      this.timeOutSearch = setTimeout(() => {
        const filters = {
          search: this.search,
          page: 1,
          limit: this.perPage
        };

        this.$emit("loadEpisodes", filters);
      }, Config.get("time_out_search"));
    },

    changePage(page) {
      const filters = {
        page: page,
        limit: this.perPage
      };

      this.$emit("loadEpisodes", filters);
    },

    action(item, row) {
      switch (item.action) {
        case "copy_embed":
        case "copy_oembed":
          this.copyEmbed(row.item, item.type);
          break;
        case "copy_url":
          this.copyUrlEpisode(row.item);
          break;
        case "delete":
          this.modalDeleteEpisode(row.item);
          break;
        case "clean":
          this.imageCleaner(row.item);
          break;
      }
    },

    copyEmbed(item, type) {
      let copyContent = document.querySelector(`#copy-embed-${item.id}`);
      copyContent.value = item.embed[this.program.id][type];
      this.copyToClipboard(copyContent);
    },

    copyUrlEpisode(item) {
      let copyContent = document.querySelector(`#copy-url-${item.id}`);
      copyContent.value = item.platformsUrl[this.program.id];
      this.copyToClipboard(copyContent);
    },

    copyToClipboard(copyContent) {
      copyContent.setAttribute("type", "text");
      copyContent.focus();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        copyContent.contentEditable = true;
        copyContent.readOnly = true;

        const range = document.createRange();
        range.selectNodeContents(copyContent);

        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        copyContent.setSelectionRange(0, 999999);
      } else {
        copyContent.select();
      }

      let message = "success";
      let variant = "success";
      let autoHideDelay = 3000;

      try {
        document.execCommand("Copy");
      } catch (err) {
        message = "unknown error";
        variant = "danger";
      }

      this.$bvToast.toast(`Copy to clipboard`, {
        title: `Copy embed to clipboard ${message}`,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: false,
        autoHideDelay: autoHideDelay
      });

      copyContent.setAttribute("type", "hidden");
    },

    modalDeleteEpisode(episode) {
      this.$bvModal.show("modal-delete-episode");
      this.selectedEpisode = episode;
    },

    async deleteEpisode() {
      this.isLoadingDelete = true;

      try {
        await deleteEpisode(
          // remove link between current & episode not with primary if not necessary
          this.program.id,
          this.selectedEpisode.id,
          this.axios
        );

        this.showToast({
          title: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.EPISODE.SUCCESS.TITLE"
          ),
          message: this.$tc(
            "ADVANCED_SETTINGS.CONTENT.TOAST.EPISODE.SUCCESS.MESSAGE_DELETE"
          ),
          variant: "success"
        });

        const filters = {
          search: this.search,
          page: 1,
          limit: this.perPage
        };

        this.$emit("loadEpisodes", filters);

        this.$bvModal.hide("modal-delete-episode");
        this.selectedEpisode = {};
      } catch (error) {
        console.error("---ERROR-DELETE-PROGRAM---");
        console.error(error);
        this.$bvModal.hide("modal-delete-episode");
        this.selectedEpisode = {};
      }

      this.isLoadingDelete = false;
    },

    showToast({ title, variant, message }) {
      const h = this.$createElement;
      const id = `toaster-message`;
      const $contentToaster = h("span", message);

      this.$bvToast.toast([$contentToaster], {
        id: id,
        title: title,
        variant: variant,
        noCloseButton: false
      });
    },

    imageCleaner(episode) {
      cleanImagesEpisode(this.program.id, episode.id, this.axios)
        .then(() => {
          this.$bvToast.toast(`Image cleaned`, {
            title: `Image cleaner`,
            solid: true,
            variant: "success",
            appendToast: false,
            autoHideDelay: 3000
          });
        })
        .catch(error => {
          const errors =
            error.response &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("errors")
              ? error.response.data.errors
              : [`Image cleaned error`];

          const text = errors.join(", ");

          this.$bvToast.toast(`${text}`, {
            title: `Image cleaner`,
            solid: true,
            variant: "danger",
            appendToast: false
          });
        });
    }
  }
};
</script>

<style>
.dropdown-menu {
  padding-left: 0px !important;
}
</style>
