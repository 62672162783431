import { ROLES } from "@/common/config/acl/roles";

export const PAGE_PROGRAMS = {
  addContent: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER
    ]
  }
};

export const FORM_PROGRAMS = {
  platformUrl: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  deleted: {
    defaultValue: false,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [],
    visible: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.ADVERTISER_ADMIN
    ]
  },
  homeDisplay: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ]
  },
  status: {
    defaultValue: true,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ]
  },
  url: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  authorLink: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [ROLES.BBB_ADMIN]
  },
  urlHash: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  id: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  name: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  title: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  description: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  isExplicit: {
    defaultValue: false,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  ownerEmail: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  keywords: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  image: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  language: {
    defaultValue: "fr",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ]
  },
  type: {
    defaultValue: "episodic",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  author: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  },
  programDomains: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [ROLES.BBB_ADMIN]
  },
  domainObject: {
    defaultValue: "",
    read: [],
    write: []
  },
  itunesSubCategories: {
    defaultValue: "",
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: []
  }
};

export const FORM_ADVERTISEMENT = {
  authorizedAds: {
    defaultValue: false,
    read: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER],
    write: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
  },
  authorizedAudioAds: {
    defaultValue: false,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [ROLES.BBB_ADMIN]
  },
  audioAdsBackfilled: {
    defaultValue: false,
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  audioAdsUrl: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [ROLES.BBB_ADMIN]
  },
  capping: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER
    ]
  }
};

export const FORM_ADVANCED = {
  homeDisplay: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ]
  },
  isForced: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  cluster: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [ROLES.BBB_ADMIN, ROLES.BBB_MANAGER_PUBLISHER]
  },
  parentId: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER,
      ROLES.PUBLISHER_ADMIN,
      ROLES.PUBLISHER_USER,
      ROLES.ADVERTISER_ADMIN,
      ROLES.ADVERTISER_USER
    ],
    write: [ROLES.BBB_ADMIN]
  },
  tags: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  },
  redirectTo: {
    read: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ],
    write: [
      ROLES.BBB_ADMIN,
      ROLES.BBB_MANAGER_PUBLISHER,
      ROLES.BBB_MANAGER_ADVERTISER
    ]
  }
};
