<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-list-episode"
  >
    <div class="card m-3">
      <div class="card-header">
        <div
          class="card-title collapsed py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_listing-episode"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_EPISODE.TITLE") }}
            <span class="text-danger">*</span>
          </span>
        </div>
      </div>

      <div
        id="collapse_listing-episode"
        class="collapse"
        data-parent="#accordion-list-episode"
      >
        <div class="card-body">
          <div class="mt-3">
            <b-form-input
              v-model="search"
              placeholder="Search episode or program"
              class="form-control--grey"
              @input="searchEpisodes"
            />
          </div>

          <div v-if="!checkEpisodes" class="mt-5">
            <b-alert variant="warning" show
              >{{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_EPISODE.WARNING") }}
            </b-alert>
          </div>

          <b-table
            striped
            hover
            show-empty
            :fields="headers"
            :items="episodesLists"
            :busy="isLoading"
            aria-controls="listing-epsiodes"
            @sort-changed="sortChanged"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_EPISODE.LOADING")
                }}</strong>
              </div>
            </template>

            <template v-slot:head(checkbox)>
              <span>{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_EPISODE.LABEL_SELECT_COL")
              }}</span>
            </template>

            <template v-slot:cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.item.selected"
                class="align-middle"
                @change="changeEpisode(row.item)"
              ></b-form-checkbox>
            </template>

            <template v-slot:cell(program)="row">
              <span>{{ row.item.primaryProgram.name }}</span>
            </template>
            <template v-slot:cell(publicationAt)="row">
              <span>{{
                new Date(row.item.publicationAt).toLocaleDateString("fr")
              }}</span>
            </template>

            <template v-slot:cell(duration)="row">
              <span>{{ row.item.duration }}</span>
            </template>
          </b-table>

          <div class="d-flex justify-content-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              aria-controls="listing-epsiodes"
              @change="changePage"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { headers } from "@/components/settings/formsPogram/programs.config.js";
import { limitPerPage as limitEpisodesPerPage } from "@/components/settings/formsPogram/programs.config.js";

export default {
  props: {
    isCreating: {
      type: Boolean,
      required: false
    },
    programId: {
      type: String,
      required: false
    },
    domainId: {
      type: String,
      required: false
    },
    episodesLists: {
      type: Array,
      required: false,
      default: () => []
    },
    totalItems: {
      type: Number,
      required: true
    },
    episodesSelected: {
      type: Array,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      search: "",
      timeoutSearch: {},
      headers,
      currentPage: 1,
      perPage: limitEpisodesPerPage,
      allEpisodesCheck: [],
      sortBy: undefined,
      sortDesc: true
    };
  },

  mounted() {
    this.allEpisodesCheck = [...this.episodesSelected];
  },

  computed: {
    getProgramId() {
      let params = this.$route.params.id;

      if (params === "create") {
        params = null;
      }

      return params;
    },
    getAllEpisodesCheck() {
      return this.episodesLists.filter(element => element.selected);
    },

    checkEpisodes() {
      return (
        this.getAllEpisodesCheck.length > 0 ||
        this.episodesSelected.length > 0 ||
        this.allEpisodesCheck.length > 0
      );
    },

    hasErrors() {
      return !this.checkEpisodes;
    }
  },

  methods: {
    isEpisodeSelected(item) {
      for (const element in item) {
        if (item[element].program.id === this.getProgramId) {
          return true;
        }
      }
    },
    sortChanged: function(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      this.loadItems();
    },
    searchEpisodes() {
      this.currentPage = 1;

      if (this.search && this.search.length > 2) {
        clearTimeout(this.timeoutSearch);

        this.timeoutSearch = setTimeout(() => {
          this.loadItems();
        }, 1000);
      }

      if (!this.search) {
        this.search = "";
        this.$emit("loadItems", {
          page: this.currentPage,
          domain: this.domainId,
          sortField: this.sortBy,
          sortDirection: this.sortDesc
        });
      }
    },

    resetData() {
      this.search = "";
      this.timeoutSearch = {};
      this.currentPage = 1;
      this.allEpisodesCheck = [];
    },

    changeEpisode(item) {
      if (item.selected) {
        this.allEpisodesCheck.push(item.id);
      } else {
        const index = this.allEpisodesCheck.findIndex(
          element => element === item.id
        );
        this.allEpisodesCheck.splice(index, 1);
      }
    },

    changePage(page) {
      this.currentPage = page;
      this.loadItems();
    },

    loadItems() {
      this.$emit("loadItems", {
        page: this.currentPage,
        search: this.search,
        domain: this.domainId,
        sortField: this.sortBy,
        sortDirection: this.sortDesc
      });

      this.episodesLists.forEach((item, index) => {
        this.episodesLists[index].selected = this.isEpisodeSelected(
          item.programEpisodes
        );
      });
    }
  },

  watch: {
    episodesLists() {
      this.allEpisodesCheck.forEach(element => {
        const index = this.episodesLists.findIndex(
          episode => episode.id === element
        );

        if (index !== -1) {
          this.episodesLists[index].selected = true;
        }
      });
    }
  }
};
</script>
