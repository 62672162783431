<template>
  <div
    class="accordion accordion-solid accordion-toggle-plus"
    id="accordion-form-podcast"
  >
    <div class="card m-3 mt-8">
      <div class="card-header">
        <div
          class="card-title py-3 pr-10 font-weight-normal"
          data-toggle="collapse"
          data-target="#collapse_program"
        >
          <span>
            {{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TITLE") }}
            <span class="text-danger">*</span>
          </span>
        </div>
      </div>

      <div
        id="collapse_program"
        class="collapse show"
        data-parent="#accordion-form-podcast"
      >
        <div class="card-body">
          <div
            v-if="hasAccess('programDomains', 'read')"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 w-25"
              >{{
                $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_DOMAIN")
              }}
              <span class="text-danger">*</span></label
            >
            <div class="d-flex flex-column w-75">
              <Combobox
                isSearchBack
                :isCanCheck="isCanCheck"
                :value="domain"
                placeholder="Select a domain"
                :items="listDomains"
                fieldName="name"
                :itemSelected="domainObject"
                :errorMessage="
                  $tc('ADVANCED_SETTINGS.CONTENT.FORM_ERROR.DOMAIN')
                "
                @loadItems="eventLoadDomain"
                @selectElement="selectDomain"
                ref="combobox"
                :disabled="
                  !hasAccess('programDomains', 'write') ||
                    listDomains.length === 1
                "
              />
            </div>
          </div>

          <div
            v-if="hasAccess('url', 'read') && !isFormPlaylist"
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
          >
            <label class="mr-2 mb-0 w-25"
              >{{ $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_FLUX") }}
              <span class="text-danger">*</span></label
            >

            <div class="d-flex flex-column w-75">
              <b-form-input
                v-model="formData.url"
                :state="checkurl"
                class="form-control--grey"
                :disabled="!hasAccess('url', 'write') && !isCreating"
              />

              <b-form-invalid-feedback :state="checkurl">
                {{ $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.FEED_URL") }}
              </b-form-invalid-feedback>
            </div>
          </div>

          <template v-if="isExpandForm">
            <div
              v-if="hasAccess('name', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{ getLabelForName }} <span class="text-danger">*</span></label
              >

              <div class="d-flex flex-column w-75">
                <b-form-input
                  v-model="formData.name"
                  class="form-control--grey"
                  :state="checkName"
                  :maxlength="limitCharactersName"
                  :disabled="!hasAccess('name', 'write')"
                />

                <div class="d-flex">
                  <div class="mr-auto">
                    <b-form-invalid-feedback :state="checkName">
                      {{ $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.NAME") }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mb-0 pb-0">
                    <span
                      class="text-right text-small text-muted pr-2"
                      :class="{
                        'red--text': checkLengthName
                      }"
                    >
                      {{ getRemainingCharactersName }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_SUBTITLE")
                }}
                <HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_SUBTITLE'
                    )
                  "
                />
              </label>

              <div
                v-if="hasAccess('name', 'read')"
                class="d-flex flex-column w-75"
              >
                <b-form-input
                  v-model="formData.title"
                  class="form-control--grey"
                  :maxlength="limitCharactersSubtitle"
                  :disabled="!hasAccess('name', 'write')"
                />

                <div class="d-flex justify-content-end">
                  <span
                    class="text-right text-small text-muted pr-2"
                    :class="{
                      'red--text': checkLengthSubtitle
                    }"
                  >
                    {{ getRemainingCharactersSubtitle }}
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="hasAccess('image', 'read') && isFormPlaylist"
              class="d-flex flew-row mb-3"
            >
              <label class="mr-2 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_THUMBNAILS"
                  )
                }}
                <span class="text-danger">*</span>
                &nbsp;<HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_THUMBNAILS'
                    )
                  "
                />
              </label>
              <div class="d-flex flex-column">
                <div
                  :disabled="!hasAccess('image', 'write')"
                  class="position-relative"
                  :class="{ 'img-playlist': hasAccess('image', 'write') }"
                  @click="uploadImage"
                  style="width: 150px"
                >
                  <i class="far fa-edit icon-sm icon-upload-image"></i>

                  <img
                    v-if="previewThumbnails"
                    :src="previewThumbnails"
                    class="rounded border"
                    width="150"
                    height="150"
                    style="background: #f4f6f9"
                    alt=""
                  />

                  <div
                    v-if="
                      Object.keys(formData?.image).length === 0 &&
                        !previewThumbnails
                    "
                    class="placeholder-thumbnails rounded border"
                    :class="{ 'bpositionOnHome-danger': checkThumbnails }"
                  ></div>
                </div>

                <span class="blue--text font-italic pt-2"
                  >{{
                    $tc(
                      "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.FORMAT_THUMBNAILS"
                    )
                  }}
                </span>

                <b-form-invalid-feedback :state="checkThumbnails">
                  {{ $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.THUMBNAILS") }}
                </b-form-invalid-feedback>

                <input
                  @input="addThumbnails"
                  ref="upload"
                  class="d-none"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                />
              </div>
            </div>

            <template v-if="isFormPlaylist">
              <div
                class="d-flex justify-content-between align-items-sm-center mb-3"
              >
                <div
                  v-if="hasAccess('itunesSubCategories', 'read')"
                  class="d-flex flex-row w-100"
                >
                  <label class="mr-2 mb-0 w-25"
                    >{{
                      $tc(
                        "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_ITUNES"
                      )
                    }}
                    <span class="text-danger">*</span>
                    &nbsp;<HelperTooltip
                      icon="mdi-help-circle"
                      :text="
                        $tc(
                          'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_ITUNES'
                        )
                      "
                    />
                  </label>

                  <div class="d-flex flex-column justify-content-between w-75">
                    <b-form-select
                      v-model="formData.itunesSubCategories"
                      :options="listItunesCategories"
                      class="form-control--grey"
                      :state="checkCategory"
                      @change="changeListItunes"
                      :disabled="!hasAccess('itunesSubCategories', 'write')"
                    ></b-form-select>

                    <b-form-invalid-feedback :state="checkCategory">
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.CATEGORIY")
                      }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </template>

            <div
              v-if="hasAccess('author', 'read') && isFormPlaylist"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_AUTHOR")
                }}
                <span class="text-danger">*</span>
                &nbsp;<HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_AUTHOR'
                    )
                  "
                />
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-input
                  v-model="formData.author"
                  class="form-control--grey"
                  :state="checkAuthor"
                  :disabled="!hasAccess('author', 'write')"
                ></b-form-input>

                <b-form-invalid-feedback :state="checkAuthor">
                  {{ $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.AUTHOR") }}
                </b-form-invalid-feedback>
              </div>
            </div>

            <div
              v-if="hasAccess('authorLink', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_COPYRIGHT"
                  )
                }}
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-input
                  v-model="formData.authorLink"
                  placeholder="https://"
                  class="form-control--grey"
                  :state="checkCopyrightLink"
                  :disabled="!hasAccess('authorLink', 'write') && disabledInput"
                />

                <b-form-invalid-feedback :state="checkCopyrightLink">
                  {{
                    $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.COPYRIGHT_LINK")
                  }}
                </b-form-invalid-feedback>
              </div>
            </div>

            <div
              v-if="hasAccess('ownerEmail', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_COPYRIGHT_EMAIL"
                  )
                }}
                <span class="text-danger">*</span></label
              >

              <div class="d-flex flex-column w-75">
                <b-form-input
                  v-model="formData.ownerEmail"
                  class="form-control--grey"
                  :state="checkownerEmail"
                  :disabled="!hasAccess('ownerEmail', 'write') && disabledInput"
                />

                <b-form-invalid-feedback :state="checkownerEmail">
                  {{
                    $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.COPYRIGHT_EMAIL")
                  }}
                </b-form-invalid-feedback>
              </div>
            </div>

            <div
              v-if="hasAccess('description', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_DESCRIPTION"
                  )
                }}
                <span class="text-danger">*</span>
                &nbsp;<HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_DESCRIPTION'
                    )
                  "
                />
              </label>

              <div
                v-if="hasAccess('description', 'write')"
                class="d-flex flex-column w-75"
              >
                <ckeditor
                  v-model="formData.description"
                  :state="checkDescription"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>

                <div class="d-flex">
                  <div class="mr-auto">
                    <b-form-invalid-feedback
                      :state="checkDescription"
                      class="text-left"
                    >
                      {{
                        $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.DESCRIPTION")
                      }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="mb-0 pb-0">
                    <p
                      class="text-right text-small text-muted pr-2"
                      :class="{
                        'red--text': this.formData.description
                          ? this.formData.description.length >
                            this.limitCharactersDescription
                          : false
                      }"
                    >
                      {{ getRemainingCharactersDescription }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                v-else-if="
                  !hasAccess('description', 'write') &&
                    hasAccess('description', 'read')
                "
                class="w-75"
              >
                <b-form-textarea
                  v-model="formData.description"
                  rows="6"
                  disabled
                  class="form-control--grey"
                ></b-form-textarea>
              </div>
            </div>

            <div
              v-if="hasAccess('isExplicit', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_EXPLICIT")
                }}
                <HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_EXPLICIT'
                    )
                  "
                />
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-checkbox
                  v-model="formData.isExplicit"
                  size="lg"
                  name="check-button"
                  switch
                  :disabled="!hasAccess('isExplicit', 'write')"
                />
              </div>
            </div>

            <div
              v-if="hasAccess('type', 'read') && isFormPlaylist"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc("ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_TYPE")
                }}
                <span class="text-danger">*</span>
                <HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc('ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_TYPE')
                  "
                />
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-select
                  v-model="formData.type"
                  :options="listOrders"
                  class="form-control--grey"
                  :state="checkProgramType"
                  :disabled="!hasAccess('type', 'write')"
                ></b-form-select>

                <b-form-invalid-feedback :state="checkProgramType">
                  {{ $tc("ADVANCED_SETTINGS.CONTENT.FORM_ERROR.TYPE") }}
                </b-form-invalid-feedback>
              </div>
            </div>

            <div
              v-if="hasAccess('language', 'read')"
              class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center mb-3"
            >
              <label class="mr-2 mb-0 w-25"
                >{{
                  $tc(
                    "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_LANGUAGES"
                  )
                }}
                &nbsp;<HelperTooltip
                  icon="mdi-help-circle"
                  :text="
                    $tc(
                      'ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.TOOLTIP_LANGUAGES'
                    )
                  "
                />
              </label>

              <div class="d-flex flex-column w-75">
                <b-form-select
                  v-model="formData.language"
                  :options="listsLanguages"
                  class="form-control--grey"
                  :disabled="!hasAccess('language', 'write')"
                ></b-form-select>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllDomainsPremiumV2 } from "@/api/domains/getters.js";
import { isURL } from "@/common/functions";
import { isEmail } from "@/common/functions.js";
import HelperTooltip from "@/components/HelperTooltip";
import { getItunesCategories } from "@/api/programs/getters";
import Combobox from "@/components/settings/Combobox";
import {
  listOrders,
  listsLanguages,
  limitCharactersName,
  limitCharactersSubtitle,
  limitCharactersDescription
} from "@/components/settings/formsPogram/programs.config.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FORM_PROGRAMS_PLAYLIST } from "@/common/config/acl/programs/playlist/abac";
import {
  hasAttributeAccess,
  getAttributesRead
} from "@/common/config/acl/index";
import { FORM_PROGRAMS } from "@/common/config/acl/programs/abac";

export default {
  props: {
    isCanCheck: {
      type: Boolean,
      required: false
    },
    isAdmin: {
      type: Boolean,
      required: false
    },
    form: {
      type: Object,
      required: false
    },
    programId: {
      type: String,
      required: false
    },
    domainsList: {
      type: Array,
      required: false
    },
    isCreating: {
      type: Boolean,
      required: false
    },
    isFormPlaylist: {
      type: Boolean,
      required: false
    },
    currentUser: {
      type: Object,
      required: false
    }
  },

  components: {
    Combobox,
    HelperTooltip
  },

  data() {
    return {
      formData: getAttributesRead(
        this.isFormPlaylist ? FORM_PROGRAMS_PLAYLIST : FORM_PROGRAMS,
        this.currentUser.roles
      ),
      domain: "",
      domainObject: {},
      previewThumbnails: "",
      listDomains: [],
      listItunesCategoriesSelected: [],
      listItunesCategories: [],
      listOrders,
      listsLanguages,
      fileImage: {},
      itunesSubCategories: "",
      limitCharactersName,
      limitCharactersDescription,
      limitCharactersSubtitle,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "undo",
          "redo",
          "|",
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "link",
          "blockQuote",
          "|",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent"
        ],
        charset: "utf-8"
      }
    };
  },

  mounted() {
    this.loadPremiumsDomains({ page: 1, search: "" });

    if (this.isFormPlaylist) {
      this.loadItunesCategories();
    }

    this.author = this.currentUser.company.name;
    this.ownerEmail = this.currentUser.email;

    if (this.programId) {
      if (this.form.programDomains) {
        // TODO: must be use primaryBroadcastDomain from getProgramsFromV2 response
        const programDomains = this.form.programDomains;

        if (programDomains.length === 1) {
          const programDomainElected = programDomains[0];

          this.domain = programDomainElected.domain.name;
          this.domainObject = { ...programDomainElected.domain };
        } else {
          const programDomainPrimary = programDomains.find(
            programDomain => programDomain.isPrimaryDomain
          );

          if (programDomainPrimary) {
            this.domain = programDomainPrimary.domain.name;
            this.domainObject = { ...programDomainPrimary.domain };
          }
        }

        this.$emit("changeDomain", this.domainObject.id);
      }

      this.formData = this.form;
      this.previewThumbnails = this.form.image;

      if (this.form.id && this.form.domain.length > 0) {
        this.domainObject = this.domainsList.find(
          element => element.id === this.form.domain
        );
        if (this.domainObject.id) {
          this.domain = this.domainObject.name;
        }
      }
    }
  },

  computed: {
    checkurl() {
      if (!this.isFormPlaylist && this.isCanCheck) {
        return isURL(this.formData.url);
      }

      return this.isCanCheck ? true : null;
    },

    checkName() {
      if (this.isExpandForm && this.isCanCheck) {
        return this.formData.name.length > 3 ? true : false;
      }

      return this.isCanCheck ? true : null;
    },

    checkLengthName() {
      if (this.isExpandForm && this.formData.name) {
        return this.formData.name.length > this.limitCharactersName;
      }

      return true;
    },

    checkLengthSubtitle() {
      return this.formData.title?.length > this.limitCharactersSubtitle;
    },

    checkAuthor() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return this.formData.author.length >= 3 ? true : false;
      }

      return this.isCanCheck ? true : null;
    },

    checkownerEmail() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return isEmail(this.formData.ownerEmail);
      }

      return this.isCanCheck ? true : null;
    },

    checkCopyrightLink() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return isURL(this.formData.authorLink);
      }

      return this.isCanCheck ? true : null;
    },

    checkDescription() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return this.formData.description.length > 0 ? true : false;
      }

      return this.isCanCheck ? true : null;
    },

    checkLengthDescription() {
      return this.formData.description.length > this.limitCharactersDescription;
    },

    checkThumbnails() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return this.formData.image.length > 0 || this.previewThumbnails
          ? true
          : false;
      }

      return this.isCanCheck ? true : null;
    },

    checkCategory() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return this.listItunesCategoriesSelected.length > 0 ? true : false;
      }

      return this.isCanCheck ? true : null;
    },

    checkProgramType() {
      if (this.isFormPlaylist && this.isCanCheck) {
        return this.formData.type.length > 0 ? true : false;
      }

      return this.isCanCheck ? true : null;
    },

    hasErrors() {
      return (
        !this.checkurl ||
        !this.$refs.combobox.checkCombobox ||
        !this.checkownerEmail ||
        !this.checkCopyrightLink ||
        !this.checkCategory ||
        !this.checkAuthor ||
        !this.checkDescription ||
        !this.checkName ||
        !this.checkProgramType ||
        !this.checkThumbnails ||
        this.checkLengthName ||
        this.checkLengthSubtitle ||
        this.checkLengthDescription
      );
    },

    getCompanyUser() {
      return this.currentUser.company;
    },

    isExpandForm() {
      return this.isFormPlaylist || !this.isCreating;
    },

    getRemainingCharactersName() {
      if (this.formData.name) {
        return `${this.formData.name.length} / ${this.limitCharactersName}`;
      }

      return "";
    },

    getRemainingCharactersSubtitle() {
      if (this.formData.title) {
        return `${this.formData.title.length} / ${this.limitCharactersSubtitle}`;
      }

      return "";
    },

    getRemainingCharactersDescription() {
      if (this.formData.description) {
        return `${this.formData.description.length} / ${this.limitCharactersDescription}`;
      }

      return "";
    },

    getLabelForName() {
      return this.isFormPlaylist
        ? this.$tc(
            "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_PLAYLIST_NAME"
          )
        : this.$tc(
            "ADVANCED_SETTINGS.CONTENT.CONFIG_PROGRAM.LABEL_PROGRAM_NAME"
          );
    },

    disabledInput() {
      return !this.isFormPlaylist && !this.isCreating;
    }
  },

  methods: {
    eventLoadDomain(filters) {
      this.loadPremiumsDomains(filters);
    },

    hasAccess(field, accessType) {
      return hasAttributeAccess(
        this.isFormPlaylist ? FORM_PROGRAMS_PLAYLIST : FORM_PROGRAMS,
        this.currentUser.roles,
        field,
        accessType
      );
    },

    async loadPremiumsDomains({ page, search }) {
      this.isLoadingPodcast = true;

      try {
        const response = await getAllDomainsPremiumV2(
          { page, search },
          this.axios
        );
        this.listDomains = response.data;

        if (this.listDomains.length === 1) {
          this.selectDomain(this.listDomains[0]);
        }
      } catch (error) {
        console.error("---ERROR-DOMAINS---");
        console.error(error);
      }

      this.isLoadingPodcast = false;
    },

    selectDomain(item) {
      this.domain = item.name;
      this.domainObject = item;
      this.$emit("changeDomain", item.id);
    },

    changeListCategories(items) {
      this.listCategoriesSelected = items;
    },

    changeListLanguages(items) {
      this.listLanguagesSelected = items;
    },

    uploadImage() {
      if (this.hasAccess("image", "write")) {
        this.$refs.upload.click();
      }
    },

    addThumbnails(e) {
      const files = e.target.files;
      if (files.length > 0) {
        const file = files[0];

        const allowExtensions = this.$refs.upload.accept
          .split(",")
          .map(string => string.trim());

        if (!allowExtensions.includes(file.type)) {
          return;
        }

        this.fileImage = file;

        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.previewThumbnails = URL.createObjectURL(file);
      }
    },

    async loadItunesCategories() {
      try {
        const response = await getItunesCategories(this.axios);

        let category = Object.values(response)[0].itunesCategory.nameEN;

        let options = [];
        response.forEach(item => {
          let itunesCategory = item.itunesCategory.nameEN;

          if (category !== itunesCategory) {
            this.listItunesCategories.push({
              label: category,
              options: options
            });
            category = itunesCategory;
            options = [];
          }

          options.push({
            text: item.nameEN,
            value: item.id
          });
        });

        if (this.form.itunesSubCategories.length > 0 && !this.isCreating) {
          this.changeListItunes(this.form.itunesSubCategories[0].id);
        }
      } catch (error) {
        console.error("---ERROR-ITUNES-CATEGORIES---");
        console.error(error);
      }
    },

    changeListItunes(value) {
      this.listItunesCategoriesSelected = [value];
      this.formData.itunesSubCategories = value;
    },

    resetData() {
      this.form = getAttributesRead(
        this.isFormPlaylist ? FORM_PROGRAMS_PLAYLIST : FORM_PROGRAMS,
        this.currentUser.roles
      );
      this.itunesSubCategories = "";
      this.previewThumbnails = "";
      this.listItunesCategoriesSelected = [];
    }
  }
};
</script>

<style scoped>
.card-choice:hover {
  background: #f8f9fa;
  color: #3699ff;
  cursor: pointer;
}

.img-playlist:hover > .icon-upload-image:hover {
  color: #3699ff;
  cursor: pointer;
}

.icon-upload-image {
  position: absolute;
  padding-top: 45%;
  padding-left: 45%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.placeholder-thumbnails {
  height: 150px;
  width: 150px;
  background: #f4f6f9;
}
</style>
