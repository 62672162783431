export const headers = [
  { label: "Selected", key: "checkbox" },
  { label: "Title", key: "title", sortable: true },
  { label: "Program", key: "program", sortable: true },
  { label: "Publication date", key: "publicationAt", sortable: true },
  { label: "Duration", key: "duration", sortable: true }
];

export const listOrders = ["serial", "episodic"];

export const listsLanguages = ["fr", "en", "es", "de", "it"];

export const partitionList = [
  "low_premium",
  "premium",
  "extra_premium",
  "advertiser",
  "playlist",
  "free"
];

export const headersEpisodeList = [
  {
    label: "Title",
    key: "title",
    sortable: true
  },
  {
    label: "Publication date",
    key: "publicationAt",
    sortable: true
  },
  {
    label: "Duration",
    key: "duration",
    sortable: true
  },
  {
    label: "Action",
    key: "action",
    sortable: false
  }
];

export const limitCharactersName = 100;
export const limitCharactersSubtitle = 255;
export const limitCharactersDescription = 4000;

export const limitPerPage = 10;
